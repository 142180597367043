import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Header from '../components/Header';

// import css from '../css/pages/newsletter.module.css';

class Thanks extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Layout>
        <SEO title="Thank You – Cyberbiomes" />
        <Header />
        <section className="raised">
          <div className="slide">
            <div className="wrap-s">
              <h1 className="ta-center">Thank you</h1>
              <div className="ta-center">
                <p>
                  Thanks for signing up for our newsletter. <br /> We do our best to bring you the
                  most fascinating and cutting-edge material each month.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Thanks;
